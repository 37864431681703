/**
 * GA4イベント送信のための関数をexportするファイル
 * 関数の使用箇所には下記のコメントを記載
 * // GA4イベント送信
 *
 * @packageDocumentation
 */
import { ParsedUrlQuery } from 'querystring'

import { Job } from '@/oas/public/api'

import { GTMModalViewName } from './types/common'
import { GA4Item, GAConfigVariables, GAEventName, GAEventVariables } from './types/ga'
import { GTMEventName } from './types/gtm'
import { sendGTM } from './gtmEventActions'

const gtmEventName: GTMEventName = 'ga_event'

function sendGAEvent(ga_event_name: GAEventName, params?: GAEventVariables) {
  sendGTM({
    event: gtmEventName,
    ga_event_name: ga_event_name,
    ...params,
  })
}
/**
 * jobをGA4Itemに変換
 * @param {Job} job - Job
 * @param {number} list_index - リスト内に表示された位置。0から始まる
 * @param {string} [prefecture_name] - 絞られている場合の県名（オプション）
 * @returns {Array<GA4Item>} - GA4Item
 */
function jobToGA4Item(job: Job, list_index: number, prefecture_name?: string): GA4Item {
  return {
    item_id: job.id, // job_id
    item_name: job.id,
    index: list_index, // リスト内に表示された位置。0から始まる
    item_category: job.job_category?.label ?? '', // 職種名を入れる
    item_category2: prefecture_name ?? '', // 絞られている場合の都道府県名を入れる
    item_category3: job.recruitment_company.name ?? '', // 掲載企業名を入れる
    item_category4: job.employment_type?.label ?? '', // 雇用形態名を入れる
    item_category5:
      job.recruitment_company.recruitment_company_industries.length !== 0
        ? job.recruitment_company.recruitment_company_industries[0].recruitment_company_industry_sm
            .label ?? ''
        : '', // 業界マスタ小を入れる
    item_variant: job.job_title ?? '', // 求人タイトルを入れる
  }
}
/**
 * jobListをGA4Itemの配列に変換
 * @param {Array<Job>} job_list - Jobの配列
 * @param {string} [prefecture_name] - 絞られている場合の県名（オプション）
 * @returns {Array<GA4Item>} - GA4Itemの配列
 */
function jobListToGA4ItemArray(job_list: Array<Job>, prefecture_name?: string): Array<GA4Item> {
  return job_list.map((job, index) => {
    return jobToGA4Item(job, index, prefecture_name)
  })
}
/**
 * クエリ文字列→計測対象のみを抽出したJSON文字列
 * @param {ParsedUrlQuery} queryJson - Json化したクエリ文字列
 * @returns {string} - search_conditionのJSON文字列
 */
function queryJsonToSearchCondition(queryJson?: ParsedUrlQuery): string {
  if (!queryJson) return '{}'
  const keysToKeep = ['workLocationPrefecture', 'jobCategory', 'particularCondition']
  const keysToChange = ['condition_1', 'condition_2', 'condition_3']
  const filteredJson: ParsedUrlQuery = {}

  // queryJsonのキーにkeysToKeepの値が含まれている場合
  if (keysToKeep.some((key) => Object.prototype.hasOwnProperty.call(queryJson, key))) {
    for (const key of keysToKeep) {
      if (Object.prototype.hasOwnProperty.call(queryJson, key)) {
        filteredJson[key] = queryJson[key]
      }
    }
  }
  // queryJsonのキーにkeysToChangeの値が含まれている場合
  if (keysToChange.some((key) => Object.prototype.hasOwnProperty.call(queryJson, key))) {
    for (const key of keysToChange) {
      const value = queryJson[key] as string[]
      if (value) {
        if (value[0].startsWith('jobCategory_')) {
          filteredJson.jobCategory = value[0].replace('jobCategory_', '')
        } else if (value[0].startsWith('workLocationPrefecture_')) {
          filteredJson.workLocationPrefecture = value[0].replace('workLocationPrefecture_', '')
        } else if (value[0].startsWith('particularCondition_')) {
          filteredJson.particularCondition = value[0].replace('particularCondition_', '')
        }
      }
    }
  }

  return JSON.stringify(filteredJson)
}
/**
 * searchイベント送信
 * @param {ParsedUrlQuery} search_term - 検索クエリ文字列全文
 * @param {number} total - 合計件数
 * @param {string} [log_job_search_condition_id] - サーバーでログIDを出している場合のログID（オプション）
 */
export function sendGASearch(
  search_term: ParsedUrlQuery,
  total: number,
  log_job_search_condition_id?: string,
) {
  sendGAEvent('search', {
    search_term: queryJsonToSearchCondition(search_term),
    total: total,
    log_job_search_condition_id: log_job_search_condition_id,
  })
}
/**
 * view_item_listイベント送信
 * @param {string} item_list_name - 検索クエリ文字列全文
 * @param {Array<Job>} job_list - 一覧に表示されたJob配列
 * @param {string} [prefecture_name] - 絞られている場合の県名（オプション）
 */
export function sendGAViewItemList(
  item_list_name: string,
  job_list: Array<Job>,
  prefecture_name?: string,
) {
  sendGAEvent('view_item_list', {
    item_list_name: item_list_name,
    items: jobListToGA4ItemArray(job_list, prefecture_name),
  })
}
/**
 * view_itemイベント送信
 * @param {string} job_id - 求人ID
 * @param {Job} job - Job
 * @param {string} [prefecture_name] - 絞られている場合、県名（オプション）
 * @param {number} [job_list_order_no] - 取得可能な場合、0始まりのリスト内の表示位置（オプション）
 * @param {ParsedUrlQuery} [from_search_condition] - 取得可能な場合、一覧表示されていた時の検索クエリ文字列（オプション）
 */
export function sendGAViewItem(
  job_id: string,
  job: Job,
  prefecture_name?: string,
  job_list_order_no?: number,
  from_search_condition?: ParsedUrlQuery,
) {
  sendGAEvent('view_item', {
    job_id: job_id,
    job_list_order_no: job_list_order_no,
    from_search_condition: queryJsonToSearchCondition(from_search_condition),
    items: [jobToGA4Item(job, job_list_order_no ?? 0, prefecture_name)],
  })
}
/**
 * add_to_wishlistイベント送信
 * @param {string} job_id - 求人ID
 * @param {Job} job - Job
 * @param {string} [prefecture_name] - 絞られている場合、県名（オプション）
 * @param {number} [job_list_order_no] - 取得可能な場合、0始まりのリスト内の表示位置（オプション）
 * @param {ParsedUrlQuery} [from_search_condition] - 取得可能な場合、一覧表示されていた時の検索クエリ文字列（オプション）
 */
export function sendGAAddToWishlist(
  job_id: string,
  job: Job,
  prefecture_name?: string,
  job_list_order_no?: number,
  from_search_condition?: ParsedUrlQuery,
) {
  sendGAEvent('add_to_wishlist', {
    job_id: job_id,
    job_list_order_no: job_list_order_no,
    from_search_condition: queryJsonToSearchCondition(from_search_condition),
    items: [jobToGA4Item(job, job_list_order_no ?? 0, prefecture_name)],
  })
}
/**
 * begin_checkoutイベント送信
 * @param {string} job_id - 求人ID
 * @param {Job} job - Job
 * @param {string} [prefecture_name] - 絞られている場合、県名（オプション）
 * @param {number} [job_list_order_no] - 取得可能な場合、0始まりのリスト内の表示位置（オプション）
 * @param {ParsedUrlQuery} [from_search_condition] - 取得可能な場合、一覧表示されていた時の検索クエリ文字列（オプション）
 */
export function sendGABeginCheckout(
  job_id: string,
  job: Job,
  prefecture_name?: string | null,
  job_list_order_no?: number | null,
  from_search_condition?: ParsedUrlQuery | null,
) {
  sendGAEvent('begin_checkout', {
    job_id: job_id,
    job_list_order_no: job_list_order_no,
    from_search_condition: queryJsonToSearchCondition(from_search_condition ?? undefined),
    items: [jobToGA4Item(job, job_list_order_no ?? 0, prefecture_name ?? undefined)],
  })
}
/**
 * generate_leadイベント送信
 * @param {string} job_id - 求人ID
 * @param {string} source_product - 求人ID
 * @param {number} [job_list_order_no] - 取得可能な場合、0始まりのリスト内の表示位置（オプション）
 * @param {ParsedUrlQuery} [from_search_condition] - 取得可能な場合、一覧表示されていた時の検索クエリ文字列（オプション）
 */
export function sendGAGenerateLead(
  job_id: string,
  source_product: string,
  job_list_order_no?: number | null,
  from_search_condition?: ParsedUrlQuery | null,
) {
  sendGAEvent('generate_lead', {
    job_id: job_id,
    source_product: source_product,
    job_list_order_no: job_list_order_no,
    from_search_condition: queryJsonToSearchCondition(from_search_condition ?? undefined),
  })
}
/**
 * view_modal_stepイベント送信
 * @param {GTMModalViewName} [modal_step] - モーダルの中の画面名
 */
export function sendGaViewModalStep(modal_step: GTMModalViewName) {
  sendGAEvent('view_modal_step', {
    modal_step: modal_step,
  })
}
/**
 * click_more_jobイベント送信
 * @param {number} start_of_job_order_no - 何件目から
 * @param {number} end_of_job_order_no - 何件目まで
 */
export function sendGAClickMoreJob(start_of_job_order_no: number, end_of_job_order_no: number) {
  sendGAEvent('click_more_job', {
    start_of_job_order_no: start_of_job_order_no,
    end_of_job_order_no: end_of_job_order_no,
  })
}
/**
 * 会員登録ステップ2完了イベント送信
 * @param {string} source_product - ステップ2登録完了に至ったリファラー
 */
export function sendGaSingUpScoutInfoEvent(source_product: string) {
  sendGAEvent('sign_up_scout_info', {
    source_product: source_product,
  })
}

/**
 * 会員登録ステップ完了イベント送信
 * @param {string} source_product - ステップ1登録完了に至ったリファラー
 */
export function sendGASignUp(source_product: string) {
  sendGAEvent('sign_up', {
    source_product: source_product,
  })
}

/**
 * パラメータなしのGA4イベント送信
 * @param ga_event_name - 所定のイベント名
 */
export function sendGANoValueEvent(
  ga_event_name: 'login' | 'delete_account' | 'edit_profile' | 'edit_email',
) {
  sendGAEvent(ga_event_name)
}
/**
 * Googleタグのパラメータを更新
 * @param {GAConfigVariables} params - 更新するパラメータ
 */
export function setGAConfig(params: GAConfigVariables) {
  sendGTM({
    event: 'update_google_tag',
    ...params,
  })
}
/**
 * GTMで設定しているのと同じview_nameを取得する
 * SEE: https://tagmanager.google.com/#/container/accounts/6202904051/containers/171031778/workspaces/25/variables?orgId=Uk1PFwRoQmuNTsyz3lpinA
 * @param routerPath - routerのasPathで取得したパス
 */
export function getViewName(routerPath: string): string {
  const url = new URL(routerPath, 'http://dummy.com')
  const { pathname } = url
  switch (true) {
    case pathname === '/':
      return 'top'
    case pathname === '/login':
      return 'login'
    case pathname.startsWith('/job/detail') && pathname.endsWith('/inquiry'):
      return 'job_inquiry'
    case pathname.startsWith('/job/detail') && pathname.endsWith('/inquiry/confirm'):
      return 'job_inquiry_confirm'
    case pathname.startsWith('/job/detail'):
      return 'job_detail'
    case pathname.startsWith('/job/search'):
      return 'job_search'
    case pathname.startsWith('/job/keep-list'):
      return 'kept_job_list'
    case pathname.startsWith('/job'):
      return 'job_list'
    case pathname === '/account/profile':
      return 'account_profile'
    case pathname === '/account/desired-conditions':
      return 'account_desired_conditions'
    case pathname === '/account/contact':
      return 'account_contact'
    case pathname === '/account/auth-settings':
      return 'account_auth_settings'
    case pathname === '/account/notification':
      return 'account_notification'
    case pathname === '/account':
      return 'account_info'
    case pathname === '/inquiry/complete':
      return 'inquiry_complete'
    case pathname === '/inquiry/list':
      return 'inquiry_list'
    case pathname === '/registration/agent':
      return 'registration_agent'
    case pathname === '/registration/scout':
      return 'registration_scout'
    case pathname === '/scout/onboarding/start':
      return 'scout_onboarding_start'
    case pathname === '/scout/onboarding/pack-list':
      return 'scout_onboarding_pack_list'
    case pathname === '/scout/onboarding':
      return 'scout_onboarding'
    case pathname === '/scout/feedback':
      return 'scout_feedback'
    case pathname.startsWith('/scout') && pathname.endsWith('/inquiry/confirm'):
      return 'scout_inquiry_confirm'
    case pathname.startsWith('/scout') && pathname.endsWith('/inquiry'):
      return 'scout_inquiry'
    case pathname === '/scout':
      return 'scout_list'
    case /^\/scout(\/[0-9A-Za-z]{26})?$/.test(pathname):
      return 'scout_detail'
    case pathname.startsWith('/guide/category'):
      return 'guide_category'
    case pathname.startsWith('/guide/detail'):
      return 'guide_detail'
    case pathname === '/guide':
      return 'guide_top'
    default:
      return 'other'
  }
}
