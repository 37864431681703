import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { initializeApp } from 'firebase/app'
import { getAuth, signOut } from 'firebase/auth'

import { appEnv, firebaseConfig } from '@/config'
import serverSideLogger from '@/modules/utils/serverSideLogger'

const logInfo = (obj: unknown) => {
  if (typeof window === 'undefined') {
    serverSideLogger.info({
      msg: obj,
    })
  }
}

const logError = (obj: unknown) => {
  if (typeof window === 'undefined') {
    serverSideLogger.error({
      msg: obj,
    })
  }
}

const requestHandler = (request: AxiosRequestConfig) => {
  logInfo({
    url: request.url,
    method: request.method,
  })
  return request
}

const responseHandler = (response: AxiosResponse) => {
  logInfo({
    status: response.status,
  })
  return response
}
/**
 * エラーハンドラー
 * memo
 * SSRの場合、windowが存在しないため、window.location.hrefでリダイレクトできない
 * よってエラーの場合はdataをnullにして、statusCodeを添えて返却する
 * エラーを受け取った各Page内でハンドリングしてエラーコンポーネントを表示するようにする
 *
 * @param {AxiosError} error
 * @return {*}
 */
const errorHandler = async (error: AxiosError) => {
  logError(error)
  // const pathName = new URL(error.config.url as string).pathname
  // ステータスコードが401で、「/api/talent/auth/me」以外の場合はログイン画面へリダイレクト
  // const excludePath = ['/api/talent/auth/me', '/api/talent/auth/login']

  // 401エラーの場合はfirebaseのログイン情報を削除する
  if (error.response?.status === 401) {
    if (typeof window !== 'undefined') {
      const app = initializeApp(firebaseConfig)
      const firebaseAuth = getAuth(app)
      await signOut(firebaseAuth)
      // window.location.href = '/login'
    }
  }
  return Promise.reject(error)
}

const createInstance = () => {
  // TODO: ログイン周りができたらインスタンスのスイッチングできるように修正する
  const axiosInstance = axios.create({
    withCredentials: false,
    xsrfCookieName: `${appEnv.toUpperCase()}-XSRF-TOKEN`,
  })

  axiosInstance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error),
  )

  axiosInstance.interceptors.request.use((request) => requestHandler(request))

  return axiosInstance
}

export const axiosInstance = createInstance()
